import * as React from "react"
import { Link } from 'gatsby'
import Layout from '../components/layout'
import '../styles/text-page-styles.css'
import ogMetaImage from '../../static/entratalogo_1200x630.png'

export default function IntegrationsPage() {

  return (
    <Layout>
    <div className="about-us text-page-styles">
      <div className="content-cap">
        <div className="text-page-intro">
          <h1>About Us</h1>
          <p>Entrata has the goal of making life easier for property owners, property managers and residents.</p>
        </div>
        <div className="text-page-content">
          <h2>Who are we?</h2><br/><br/>
          <p>PEOPLE</p><br/><br/>
          <div className="sub-text">From our top executives to part-time employees, we pride ourselves on having the smartest team available. The entrepreneurism that started Entrata still drives the company and employees today--everyone’s willing to roll up their sleeves.</div><br/><br/>
          <p>INNOVATION</p><br/><br/>
          <div className="sub-text">Even though we work in a very traditional industry, we’re not afraid to shake things up if it means doing them better. We encourage innovation with our own products as well as with our competitors. That’s why we’ve created the industry’s first open platform.</div>
        </div>
      </div>
    </div>
    </Layout>
  )
}

export const Head = () => (
  <>
    <meta name="description" content="From our top executives to part-time employees, we pride ourselves on having the smartest team available." />
    <title>Entrata | About Us</title>
    <meta property="og:image" content={ogMetaImage} />
  </>
)
